<template>
  <footer class="footer">
    <p class="footer-text">© 2024 JUCAM. Todos los derechos reservados.</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.footer {
  background-color: #F6F8F9;
  padding: 20px 0;
  text-align: center;
}

.footer-text {
  color: #193238;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
</style>