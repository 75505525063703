<template>
  <div class="donar-page">
    <h1>Donar</h1>
    <p>Página en construcción.</p>
  </div>
</template>

<script>
export default {
  name: 'Donar',
};
</script>

<style scoped>
.donar-page {
  text-align: center;
  padding: 50px;
  color: #193238;
  font-family: 'Inter', sans-serif;
}

.donar-page h1 {
  font-weight: 700;
}

.donar-page p {
  font-weight: 500;
}
</style>