<template>
  <div class="servicios-page">
    <h1>Servicios</h1>
    <p>Página en construcción.</p>
  </div>
</template>

<script>
export default {
  name: 'Servicios',
};
</script>

<style scoped>
.servicios-page {
  text-align: center;
  padding: 50px;
  color: #193238;
  font-family: 'Inter', sans-serif;
}

.servicios-page h1 {
  font-weight: 700;
}

.servicios-page p {
  font-weight: 500;
}
</style>